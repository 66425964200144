import currencies from 'constants/currencies';

import imageWithoutGiftCAD from 'assets/images/promotionalModals/trial-offer-skincare-minis-promotion-modal-CAD-without-gift.jpg';
import imageWithoutGiftUS from 'assets/images/promotionalModals/trial-offer-skincare-minis-promotion-modal-US-without-gift.jpg';

import useResponsiveVariant from 'utils/useResponsiveVariant';

const tracking = {
  modalClosing: 'Landing - SK Minis + HC trial Popup - Closed',
  modalOpening: 'Landing - SK Minis + HC trial Popup - Viewed',
  modalOpening2sec: 'Landing - SK Minis + HC trial Popup - 2 Sec Viewed',
};

const Description = () => {
  const { isMobile } = useResponsiveVariant();
  return (
    <>
      Claim your exclusive trial offer by subscribing to any 3+ hair or skin products.{' '}
      {isMobile && <br />}
      Limited time only!
    </>
  );
};

const content = /** @type {const} */ {
  banner: 'Get 50% off haircare or try skincare for free',
  popUpModal: {
    [currencies.USD]: {
      tracking,
      image: imageWithoutGiftUS,
      headerTitle: 'EXCLUSIVE TRIAL OFFER',
      description: <Description />,
      cta: {
        ctaLabel: 'unlock offer',
      },
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order when you subscribe to 3+
          products. Get your Skincare Starter Set ($65 value) when you subscribe to Cleanser, Serum,
          and Moisturizer. Subsequent subscription orders will receive 15% off, or 20% off when
          subscribed to both Haircare and Skincare. Offers subject to expire and valid only while
          supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for
          accessories or digital gift cards. Exceptions may apply. For full details on our return
          policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    [currencies.CAD]: {
      tracking,
      image: imageWithoutGiftCAD,
      headerTitle: 'EXCLUSIVE TRIAL OFFER',
      description: <Description />,
      cta: {
        ctaLabel: 'unlock offer',
      },
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order when you subscribe to 3+
          products. Get your Skincare Starter Set when you subscribe to Cleanser, Serum, and
          Moisturizer. Subsequent subscription orders will receive 15% off, or 20% off when
          subscribed to both Haircare and Skincare. Offers subject to expire and valid only while
          supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for
          accessories or digital gift cards. Exceptions may apply. For full details on our return
          policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
  },
  bottomModal: {
    [currencies.USD]: {
      title: 'EXCLUSIVE TRIAL OFFER',
      description: (
        <>
          Unlock <b>50% off haircare</b> or a <b>FREE Skincare Starter Set</b> when you subscribe to
          3+ products.
        </>
      ),
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order when you subscribe to 3+
          products. Get your Skincare Starter Set ($65 value) when you subscribe to Cleanser, Serum,
          and Moisturizer. Subsequent subscription orders will receive 15% off, or 20% off when
          subscribed to both Haircare and Skincare. Offers subject to expire and valid only while
          supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for
          accessories or digital gift cards. Exceptions may apply. For full details on our return
          policy, click{' '}
          <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
            here
          </a>
          .
        </>
      ),
    },
    [currencies.CAD]: {
      title: 'EXCLUSIVE TRIAL OFFER',
      description: (
        <>
          Unlock <b>50% off haircare</b> or a <b>FREE Skincare Starter Set</b> when you subscribe to
          3+ products.
        </>
      ),
      conditions: (
        <>
          *Get a total of 50% off your first haircare subscription order when you subscribe to 3+
          products. Get your Skincare Starter Set when you subscribe to Cleanser, Serum, and
          Moisturizer. Subsequent subscription orders will receive 15% off, or 20% off when
          subscribed to both Haircare and Skincare. Offers subject to expire and valid only while
          supplies last. Cannot be applied to previous orders or non-subscribed items. Not valid for
          accessories or digital gift cards. Exceptions may apply. For full details on our return
          policy, click{' '}
          <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
            here
          </a>
          .
        </>
      ),
    },
  },
};

export default content;
